import React, { useState } from "react";
import classes from "./Footer.module.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import LabReport from "./LabReport";
import useDeviceWidth from "../../device-width/UseDeviceWidth";
import { useLabReport } from "../Layout";

const Footer = ({ settings, services, policies }) => {
  const navigate = useNavigate();
  const width = useDeviceWidth();
  const { showLabReportModal, setShowLabReportModal } = useLabReport();

  const quickLinks = [
    {
      title: "Home",
      link: `/`,
    },
    {
      title: "Testimonials",
      link: `/testimonials`,
    },
    // {
    //   title: "About Us",
    //   link: `/about-us`,
    // },
    {
      title: "Gallery",
      link: `/gallery`,
    },
    {
      title: "Services",
      link: `/services`,
    },
    {
      title: "Careers",
      link: `/careers`,
    },
    {
      title: "Contact Us",
      link: `/contact-us`,
    },
    {
      title: "What's New",
      link: `/blogs`,
    },
  ];

  const handleCancel = () => {
    setShowLabReportModal(false);
  };

  return (
    <>
      <div className={classes.footerContainer}>
        <div className={classes.footerSection}>
          <img
            src="/img/designs/footerBac.svg"
            alt="background"
            className={classes.footerBack}
          />
          <div className={`${classes.column}`} data-aos="fade-right" data-aos-delay="200">
            <img
              src="/img/footer-logo.svg"
              alt="logo"
              className={classes.footerlogo}
            />
            <div className={classes.divideLine}></div>
            <p className={classes.description}>
              Sabine Hospital is committed to delivering exceptional fertility
              care with a team of skilled IVF specialists. Our facility provides
              advanced in vitro fertilization (IVF) treatments alongside
              expertise in reproductive medicine and cutting-edge fertility
              technologies.
            </p>
            <div className={classes.buttonDiv}>
              <button
                className={`${classes.bookOnline} primary-hover`}
                onClick={() => navigate(`/consultation`)}
              >
                Make an Appointment
              </button>
              <button
                className={`${classes.exploreButton} primary-hover`}
                onClick={() => setShowLabReportModal(true)}
              >
                Lab Report
              </button>
            </div>
            <div className={classes.quickContactDiv}>
              <h4 className={classes.contactTitle}>Quick Contact</h4>
              <div className={classes.quickContactSection}>
                <div className={classes.quickContactItemDiv}>
                  <img
                    src="/img/icons/footer-phone.svg"
                    alt="contact"
                    className={classes.callIcon}
                  />
                  <a
                    href={`tel:${settings?.contactNumber}`}
                    className={classes.quickContactItem}
                  >
                    {settings?.contactNumber}
                  </a>
                </div>
                <div className={classes.quickContactItemDiv}>
                  <img
                    src="/img/icons/nav-whatsapp.svg"
                    alt="whatsapp"
                    className={classes.whatsappIcon}
                  />
                  <a
                    href={`https://wa.me/${settings?.whatsappNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.quickContactItem}
                  >
                    {settings?.whatsappNumber}
                  </a>
                </div>
              </div>
              <div className={classes.socialMediaDiv}>
                <a
                  href={settings?.facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/img/icons/footer-facebook.svg" alt="facebook" />
                </a>
                <a
                  href={settings?.instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/img/icons/footer-instagram.svg" alt="instagram" />
                </a>
                <a
                  href={settings?.linkedInLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/img/icons/footer-linkedin.svg" alt="linkedin" />
                </a>
                <a
                  href={settings?.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.iconCover}
                >
                  <img className={classes.youtubeIcon} src="/img/icons/footer-youtube.svg" alt="youtube" />
                </a>
              </div>
            </div>
          </div>
          <div className={`${classes.column}`} data-aos="fade-down" data-aos-delay="200">
            <h4 className={classes.columnHeading}>
              Medical Specialties and Services
            </h4>
            <ul className={classes.listing}>
              {services?.length > 0 &&
                services?.map((service) => (
                  <li onClick={() => navigate(`/services/${service?.slug}`)}>
                    <div className={classes.listIcon}></div>
                    {/* <a
                      href={`/services/${service.slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    > */}
                    <p>
                      {service.title}
                      </p>
                    {/* </a> */}
                  </li>
                ))}
            </ul>
          </div>
          <div className={`${classes.column}`} data-aos="fade-down" data-aos-delay="200">
            <div className={classes.quickLinkGrid}>
              <div className={classes.quickLinkGridCol}>
                <h4 className={classes.columnHeading}>Quick Links</h4>
                <ul className={classes.listing}>
                  {quickLinks.map((link, index) => (
                    <li key={index} onClick={() => navigate(`${link?.link}`)}>
                      <div className={classes.listIcon}></div>
                      {/* <a
                        href={link.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      > */}
                      <p>
                        {link.title}
                        </p>
                      {/* </a> */}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={classes.quickLinkGridCol}>
                <h4 className={classes.columnHeading}>Policies</h4>
                <ul className={classes.listing}>
                  {policies?.map((policy, index) => (
                    <li key={index} onClick={() => navigate(`/policy/${policy.slug}`)}>
                      <div className={classes.listIcon}></div>
                      {/* <a
                        href={`/policy/${policy.slug}`}
                        rel="noopener noreferrer"
                      > */}
                      <p>
                        {policy?.data?.title}
                        </p>
                      {/* </a> */}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* <h4 className={classes.columnHeading}>Quick Links</h4>
          <ul className={classes.listing}>
            <div className={classes.quickLinkGrid}>
              <div className={classes.quickLinkGridCol}>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Medical Tourism
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Testimonials
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Careers
                  </a>
                </li>
              </div>
              <div className={classes.quickLinkGridCol}>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    What's new
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CSR Policy
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Refund and Return Policy
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of use
                  </a>
                </li>
                <li>
                  <div className={classes.listIcon}></div>
                  <a
                    href={`/service`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </div>
            </div>
          </ul> */}
          </div>
        </div>
        <div className={classes.versionSection}>
          <h4 className={classes.versionText}>
            Copyright sabinehospital.com © {new Date().getFullYear()}. v1.1.1 |
            All rights reserved | Developed By{" "}
            <a href="https://riolabz.com" target="_blank" rel="noreferrer">
              Riolabz
            </a>
          </h4>
        </div>
      </div>
      <Modal
        centered
        className="lab-report"
        open={showLabReportModal}
        onCancel={() => handleCancel()}
        footer={null}
        width={width > 1200 ? 664 : width > 768 ? 584 : 320}
      >
        <LabReport setShowLabReportModal={setShowLabReportModal} />
      </Modal>
    </>
  );
};

export default Footer;
