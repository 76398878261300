import axios from 'axios';
import { notification } from 'antd';

// Create an axios instance
const fetchInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://sabineapi.riolabz.com/api/v1/', // Set your base API URL in environment variables
  timeout: 60000, // Timeout after 10 seconds
});

// Request interceptor to add token
fetchInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Get token from localStorage or any storage mechanism you're using
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Add the token to the Authorization header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle responses and errors
fetchInstance.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response.data; // Return only the data from the response
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      // Handle 401 Unauthorized
      if (status === 401) {
        // notification.error({
        //   message: 'Session Expired',
        //   description: 'Your session has expired. Please log in again.',
        // });

        // Clear token and redirect to login
        localStorage.removeItem('token');
        // window.location.href = '/login'; // Adjust to your login route
        // return Promise.resolve(error);
      } else {
        // Handle other errors with a notification
        notification.error({
          message: 'Error',
          description: data?.message || 'Something went wrong. Please try again.',
        });
        // return Promise.resolve({ error: true, message: data?.message || 'Error occurred' });
      }
    } else {
      // Handle network errors
      // notification.error({
      //   message: 'Network Error',
      //   description: 'Unable to connect to the server. Please check your internet connection.',
      // });
      // return Promise.resolve({ error: true, message: 'Network Error' });
    }
    // return Promise.resolve(error);
    return Promise.reject(error);
  }
);

const fetch = fetchInstance;
export default fetch;
